.pulse {
    cursor: pointer;
    color: #fff;
    width: 100%;
    max-width: 350px;
    border-radius: 10px;
    background: #A8201A;
    border: 2px solid #A8201A;
    animation: pulse 2s infinite;
    transition: all 0.3s ease-in-out;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(168, 32, 26, 0.4);
    }
    100% {
        box-shadow: 0 0 0 25px rgba(168, 32, 26, 0);
    }
}
