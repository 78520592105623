.before-after {
    background: url(../../assets/step8/before-after.jpg) no-repeat center center;
}

.stroke {
    position: relative;

    &:after {
        content: '';
        background: url(../../assets/step8/stroke.png) no-repeat bottom;
        position: absolute;
        height: 5px;
        width: 100%;
        bottom: -4px;
        left: 0;
    }
}